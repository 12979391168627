const formatNumber = n => {
  if (n > 999999) {
    const rounded = Math.round(n / 100000) / 10;
    return `${rounded.toLocaleString()} million`;
  }

  return n.toLocaleString();
};

export default formatNumber;
