import Prevention from "charts/Prevention";
import Breakthrough from "charts/Breakthrough";
import Caseloads from "charts/Caseloads";
import TakingTheirToll from "charts/TakingTheirToll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => (
  <Router>
    <Switch>
      <Route path="/takingtheirtoll">
        <TakingTheirToll />
      </Route>
      <Route path="/caseloads">
        <Caseloads />
      </Route>
      <Route path="/prevention">
        <Prevention />
      </Route>
      <Route path="/breakthrough">
        <Breakthrough />
      </Route>

      <Route path="/">
        <a href="/takingtheirtoll">Taking their Toll</a>
        <br />
        <a href="/caseloads">Caseloads</a>
        <br />
        <a href="/prevention">Prevention</a>
        <br />
        <a href="/breakthrough">Breakthrough</a>
        <br />
      </Route>
    </Switch>
  </Router>
);

export default App;
