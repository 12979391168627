import React from "react";
import { ReactComponent as AntibioticIcon } from "../icons/antibiotic.svg";
import { ReactComponent as MedicationIcon } from "../icons/medication.svg";
import { ReactComponent as TechnologyIcon } from "../icons/technology.svg";
import { ReactComponent as VaccineIcon } from "../icons/vaccine.svg";
import s from "./style.module.css";

const Legend = () => {
  return (
    <div className={s.wrap}>
      <div className={s.item}>
        {" "}
        <VaccineIcon /> Vaccine{" "}
      </div>
      <div className={s.item}>
        {" "}
        <AntibioticIcon /> Antibiotic{" "}
      </div>
      <div className={s.item}>
        {" "}
        <MedicationIcon /> Medication{" "}
      </div>
      <div className={s.item}>
        {" "}
        <TechnologyIcon /> Technology{" "}
      </div>
    </div>
  );
};

export default Legend;
