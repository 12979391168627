import { useState, useRef } from "react";
import Visualisation from "components/Visualisation";
import Legend from "./Legend";
import s from "./style.module.css";
import YearSlider from "components/YearSlider";
import cn from "classnames";
import useComponentSize from "@rehooks/component-size";
import data from "./data.json";
import useTooltip from "helpers/useTooltip";
import formatNumber from "helpers/formatNumber";

const TakingTheirToll = ({
  notes = [
    {
      year: 1891,
      text: "Duration",
    },
    {
      year: 1892,
      text: "Number of deaths",
      isHorizontal: true,
    },
  ],
}) => {
  // const years = data.map((d) => d.year);
  const ref = useRef(null);
  const { width, height } = useComponentSize(ref);

  const [hovered, setHovered] = useState(null);

  const { ref: tooltipRef, Tooltip, onMouseOver, onMouseOut } = useTooltip();

  const minYear = 1851;
  const maxYear = 2021;
  // const maxYear = Math.max(...years);
  const [year, setYear] = useState(1895);

  const colors = {
    "Human-to-human": "#FFBF80",
    Rodents: "#4C8026",
    Insects: "#FFF266",
    Air: "#71BDD0",
    Water: "#004099",
  };

  const ticks = new Array(maxYear + 3 - minYear + 1)
    .fill()
    .map((d, i) => i + minYear);

  const getOffset = (year) => (year - minYear) * 16;
  const offset = (year - minYear) / (maxYear - minYear);

  data
    .sort((a, b) => a.from - b.from || b.spread - a.spread)
    .forEach((d, i) => {
      // d.offset = i;
      d.spread = d.to - d.from + 1;
    });

  const maxDeaths = Math.max(...data.map((d) => d.deaths));

  const overlaps = data
    .sort((a, b) => a.spread - b.spread || a.from - b.from)
    .reduce((arr, next) => {
      const itemToOffset = next;

      const overlaps = data.filter((otherItem) => {
        const isCurrent =
          otherItem.disease === itemToOffset.disease &&
          otherItem.from === itemToOffset.from &&
          otherItem.to === itemToOffset.to;

        const exists = arr.find(
          (e) =>
            e.disease === otherItem.disease &&
            e.from === otherItem.from &&
            e.to === otherItem.to
        );

        const startsBeforeEnd = otherItem.from < itemToOffset.to + 1;
        const endsAfterStart = otherItem.to + 1 > itemToOffset.from;
        const startsAtSameTime = otherItem.from === itemToOffset.from;
        const endsAtSameTime =
          otherItem.to === itemToOffset.to && startsBeforeEnd;

        return (
          ((startsBeforeEnd && endsAfterStart) ||
            startsAtSameTime ||
            endsAtSameTime) &&
          !isCurrent &&
          !exists
        );
      });

      next.offset = overlaps.length + 1;
      arr.push(next);
      return arr;
    }, []);

  const getOverlaps = (overlaps) =>
    overlaps.forEach((d) => {
      const errors = overlaps.filter((e) => {
        const sameRow = e.offset === d.offset;
        const overlaps = e.from < d.to + 1 && e.to + 1 > d.from;
        const startsAtSameTime = e.from === d.from;
        const endsAtSameTime = e.to === d.to && e.from < d.to;

        return (overlaps || startsAtSameTime || endsAtSameTime) && sameRow;
      });

      if (errors.length > 1) {
        const next = Math.max(...errors.map((d) => d.offset));
        d.offset = next + 1;
      }
    });

  getOverlaps(overlaps);
  getOverlaps(overlaps);

  return (
    <Visualisation
      title="Why we need to control outbreaks quickly"
      legend={<Legend items={colors} title={"How the outbreak spread"} />}
      interaction={
        <YearSlider
          minYear={minYear}
          maxYear={maxYear}
          minYearOverall={minYear}
          maxYearOverall={maxYear}
          onChange={setYear}
          year={year}
          hideOutput
        />
      }
      cta="Use the slider below to move the timeline and hover to reveal outbreak details"
      sources="Sources: Various, including BBC News, Centers for Disease Control and Prevention, Joint United Nations Programme on HIV/AIDS, National Center for Biotechnology Information, New World Encyclopedia, Nigeria Centre for Disease Control, The British Medical Journal, The Free Press Journal, The Lancet, World Health Organization. Notes: Outbreaks have been included if they meet the following criteria: resulted in more than 100 deaths; geographically widespread, ie a pandemic or a major epidemic within a country. It has not been possible to show all outbreaks that meet the criteria as not all death tolls could be confirmed, eg for the sixth and seventh cholera pandemics. For the purposes of this visualisation, all HIV/AIDS cases since 1981 have been considered as part of one sustained epidemic. Data as of 25 August 2021."
    >
      <div ref={ref}>
        <div className={s.scrollWrapper} ref={tooltipRef}>
          {Tooltip}
          <div
            className={s.scroll}
            style={{
              width: 16 * ticks.length,
              "--offset": `${
                Math.round(-16 * ticks.length * offset) + width * offset
              }px`,
            }}
          >
            <div className={cn(s.data, { [s.isHovered]: hovered !== null })}>
              {overlaps
                .sort((a, b) => b.deaths - a.deaths)
                .map(
                  ({ disease, from, to, deaths, type, offset, spread }, i) => (
                    <div
                      data-from={from}
                      data-to={to}
                      data-spread={spread}
                      data-disease={disease}
                      className={s.outbreak}
                      style={{
                        "--color": colors[type],
                        left: `${getOffset(from)}px`,
                        bottom: `${offset * 10 - 9 - offset}px`,
                        width: `${spread * 16 + 1}px`,
                      }}
                      onMouseEnter={() => setHovered(i)}
                      onMouseLeave={() => setHovered(null)}
                      onMouseOver={onMouseOver({
                        year: spread === 1 ? from : null,
                        from: spread > 1 ? from : null,
                        to: spread > 1 ? to : null,
                        title: disease,
                        sub: `${formatNumber(deaths)} deaths`,
                      })}
                      onMouseOut={onMouseOut()}
                    >
                      <div
                        className={s.deaths}
                        style={{
                          "--xOffset": `50%`,
                          "--yOffset": `${offset * 9}px`,
                          "--width": `${Math.max(
                            (Math.sqrt(deaths) / Math.sqrt(maxDeaths)) *
                              height *
                              0.7,
                            5
                          )}px`,
                        }}
                      ></div>
                    </div>
                  )
                )}
            </div>

            {notes.map(({ year, text, isHorizontal }) => (
              <div
                className={cn(s.note, { [s.isHorizontal]: isHorizontal })}
                style={{
                  left: `${getOffset(year)}px`,
                }}
              >
                <div className={s.noteText}>{text}</div>
                <div>
                  <svg
                    className={s.noteIcon}
                    width="8"
                    height="25"
                    viewBox="0 0 8 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.64645 24.3536C3.84171 24.5488 4.15829 24.5488 4.35355 24.3536L7.53553 21.1716C7.7308 20.9763 7.7308 20.6597 7.53553 20.4645C7.34027 20.2692 7.02369 20.2692 6.82843 20.4645L4 23.2929L1.17157 20.4645C0.97631 20.2692 0.659727 20.2692 0.464465 20.4645C0.269203 20.6597 0.269203 20.9763 0.464465 21.1716L3.64645 24.3536ZM3.5 -2.18557e-08L3.5 24L4.5 24L4.5 2.18557e-08L3.5 -2.18557e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            ))}

            <div className={s.axis}>
              {ticks.map((year) => (
                <div
                  className={cn(s.tick, {
                    [s.isLarge]: !(year % 10),
                  })}
                  style={{
                    left: `${getOffset(year)}px`,
                  }}
                >
                  <span className={s.tickText}>{year}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Visualisation>
  );
};

export default TakingTheirToll;
