import Visualisation from "components/Visualisation";
import Legend from "./Legend";
import StripeChart from "./StripeChart";
import s from "./style.module.css";
import data from "./data.json";
import groupBy from "helpers/groupBy";

const Prevention = () => {
  const dataByDisease = groupBy(data, "disease");

  return (
    <Visualisation
      title="Vaccines help eliminate infectious diseases"
      legend={<Legend title="Global cases each year" />}
      cta="Hover to reveal cases and further information"
      sources="Sources: Centers for Disease Control and Prevention, World Health Organization. Notes: Ebola cases for 2018 and 2019 are logged against 2018. Cases are logged as 0 when the disease was known but there were no cases. No data logged for years when the disease was not yet known, or the data is not yet available. Data as of 25 August 2021."
    >
      <div className={s.stripes}>
        <StripeChart
          showYears
          title="Measles"
          color={["#FFA64D", "#FFF7F0"]}
          data={dataByDisease.measles}
          keyNoData
        />
      </div>

      <div className={s.stripes}>
        <StripeChart
          title="Polio"
          color={["#71BDD0", "#F1FCFD"]}
          data={dataByDisease.polio}
          keyNoData
        />
      </div>

      <div className={s.stripes}>
        <StripeChart
          title="Ebola"
          color={["#8CC059", "#F7FCF2"]}
          data={dataByDisease.ebola}
          keyNoData
        />
      </div>
      <div className={s.stripes}>
        <StripeChart
          title="Covid-19"
          color={["#FFEA00", "#FFFEF0"]}
          data={dataByDisease.covid}
          keyNoData
        />
      </div>
    </Visualisation>
  );
};

export default Prevention;
