import Visualisation from "components/Visualisation";
import useTooltip from "helpers/useTooltip";
import Legend from "./Legend";
import s from "./style.module.css";
import data from "./data.json";

import { ReactComponent as AntibioticIcon } from "./icons/antibiotic.svg";
import { ReactComponent as MedicationIcon } from "./icons/medication.svg";
import { ReactComponent as TechnologyIcon } from "./icons/technology.svg";
import { ReactComponent as VaccineIcon } from "./icons/vaccine.svg";

const Breakthrough = () => {
  const years = data.map((d) => d.year);
  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);

  const { ref, Tooltip, onMouseOver, onMouseOut } = useTooltip();

  const addInteraction = (year, name, description) => ({
    onFocus: onMouseOver({
      year,
      title: name,
      sub: description,
    }),
    onBlur: onMouseOut({}),
    onMouseMove: onMouseOver({
      year,
      title: name,
      sub: description,
    }),
    onMouseOut: onMouseOut({}),
  });

  return (
    <Visualisation
      title="Life-saving inventions made since 1920"
      legend={<Legend />}
      cta="Hover to reveal invention"
      sources="Sources: Various, including Diabetes UK, Immunization Action Coalition, National Cancer Institute, National Institutes of Health, Nature Reviews Microbiology, Science Museum, United States Food and Drug Administration, World Health Organization Model List of Essential Medicines (2015)."
      notes=""
    >
      <div className={s.grid} ref={ref}>
        <div className={s.minYear}>
          <span>{minYear}</span>
        </div>

        {data.map(({ type, year, name, description }, i) => (
          <>
            <div className={s.breakthrough} data-year={year - (year % 10)}>
              <div className={s.year}>{year - (year % 10)}</div>
              <div>
                {type === "Antibiotic" && (
                  <AntibioticIcon
                    tabIndex={0}
                    {...addInteraction(year, name, description)}
                  />
                )}
                {type === "Medication" && (
                  <MedicationIcon
                    tabIndex={0}
                    {...addInteraction(year, name, description)}
                  />
                )}
                {type === "Technology" && (
                  <TechnologyIcon
                    tabIndex={0}
                    {...addInteraction(year, name, description)}
                  />
                )}
                {type === "Vaccine" && (
                  <VaccineIcon
                    tabIndex={0}
                    {...addInteraction(year, name, description)}
                  />
                )}
              </div>
            </div>
          </>
        ))}

        {Tooltip}
        <div className={s.maxYear}>
          <span>{maxYear}</span>
        </div>
      </div>
    </Visualisation>
  );
};

export default Breakthrough;
