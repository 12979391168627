import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import useTooltip from "helpers/useTooltip";
import formatNumber from "helpers/formatNumber";
import { scaleLinear } from "d3-scale";

const StripeChart = ({
  title,
  data = [],
  minYear = 1980,
  maxYear = 2021,
  color,
  showYears,
  keyNoData,
}) => {
  const { ref, Tooltip, onMouseOver, onMouseOut } = useTooltip();

  const years = new Array(maxYear - minYear + 1)
    .fill()
    .map((_, i) => minYear + i);

  // const min = Math.min(...data.map(d => d.value));
  const min = 0;
  const max = Math.max(...data.map((d) => d.value));
  const colorScale = scaleLinear().domain([max, min]).range(color);

  const withData = years.map((year) => {
    const d = data.find((d) => d.year === year) || {};
    return { year, ...d };
  });

  const compressNoData = withData.reduce((all, next, i) => {
    const isNoData = next.value === undefined;
    if (isNoData) {
      const prev = all[all.length - 1];
      if (prev && prev.value === undefined) {
        prev.size = prev.size + 1;
        return all;
      }
    }

    next.size = 1;

    all.push(next);

    return all;
  }, []);

  if (keyNoData && compressNoData.length) {
    const firstNoData = compressNoData.find((d) => d.value === undefined);
    if (firstNoData) {
      firstNoData.hasNoDataLabel = true;
    }
  }

  const infoOnly = data.filter((d) => d.info);

  return (
    <div className={cn(s.wrap, { [s.showYears]: showYears })} ref={ref}>
      <div className={s.title} style={{ "--bg": color[0] }}>
        {title}
      </div>

      {Tooltip}

      {compressNoData.map(
        ({ year, info, value, size, hasNoDataLabel }, i, arr) => (
          <div
            tabIndex={hasNoDataLabel ? null : 0}
            className={cn(s.year, {
              [s.noData]: value === undefined,
              [s.hasNoDataLabel]: hasNoDataLabel,
              [s.isHorizontal]: size > 7,
            })}
            onMouseOver={
              value !== undefined
                ? onMouseOver({
                    year,
                    title: `${formatNumber(value)} cases`,
                    sub: info,
                  })
                : null
            }
            onFocus={
              value !== undefined
                ? onMouseOver({
                    year,
                    title: `${formatNumber(value)} cases`,
                    sub: info,
                  })
                : null
            }
            onMouseOut={onMouseOut({})}
            onBlur={onMouseOut({})}
            style={{
              background: colorScale(value),
              flex: size,
            }}
          >
            {info && (
              <div
                className={cn(s.infoWrap, {
                  [s.odd]:
                    infoOnly.indexOf(infoOnly.find((d) => d.year === year)) % 2,
                  [s.middle]:
                    infoOnly.indexOf(infoOnly.find((d) => d.year === year)) ===
                      0 ||
                    infoOnly.indexOf(infoOnly.find((d) => d.year === year)) ===
                      infoOnly.length - 1,
                })}
              >
                <svg
                  className={s.info}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11.5"
                    fill="white"
                    stroke="#292929"
                  />
                  <path
                    d="M12.798 4.708H10.17V7.408H12.798V4.708ZM12.798 9.28H10.17V19H12.798V9.28Z"
                    fill="#292929"
                  />
                </svg>
              </div>
            )}

            {i === 0 && showYears && <div className={s.label}>{year}</div>}

            {i === arr.length - 1 && showYears && (
              <div className={s.label}>{year + size - 1}</div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default StripeChart;
