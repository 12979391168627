import React from "react";
import s from "./style.module.css";
import { ReactComponent as Logo } from "./logo.svg";

const Visualisation = ({
  title,
  sub,
  cta,
  legend,
  interaction,
  children,
  sources,
  notes,
}) => {
  return (
    <div className={s.wrap}>
      <div className={s.header}>
        {title && <h2 className={s.title}>{title}</h2>}
        {sub && <p className={s.sub}>{sub}</p>}
        {legend && <div className={s.legend}>{legend}</div>}
        {cta && <p className={s.cta}>{cta}</p>}
        {interaction && <div className={s.interaction}>{interaction}</div>}
      </div>

      {children && <div className={s.chart}>{children}</div>}

      <div className={s.footer}>
        <a className={s.logo} href="https://wellcome.org/">
          <Logo />
        </a>
        {sources && <p className={s.sources}>{sources}</p>}
      </div>
    </div>
  );
};

export default Visualisation;
