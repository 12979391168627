import { useState } from "react";
import Visualisation from "components/Visualisation";
import YearSlider from "components/YearSlider";
import TitleSquare from "components/TitleSquare";
import s from "./style.module.css";
import cn from "classnames";
import groupBy from "helpers/groupBy";
import formatNumber from "helpers/formatNumber";
import data from "./data.json";

const colors = {
  malaria: "#FFA64D",
  hiv: "#71BDD0",
  ebola: "#8CC059",
  covid: "#FFEA00",
};

const Caseloads = () => {
  const diseaseData = groupBy(data, "disease");

  const [selected, setSelected] = useState("malaria");
  const [color, setColor] = useState(colors[selected]);
  const selectedData = diseaseData[selected];

  const years = selectedData.map((d) => d.year);
  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);
  const [year, setYear] = useState(minYear);

  const max = Math.max(...selectedData.map((d) => Math.max(d.deaths, d.cases)));
  const { cases, deaths } = selectedData.find((d) => d.year === year);

  const setDisease = (key) => {
    const newData = diseaseData[key];
    const years = newData.map((d) => d.year);
    const minYear = Math.min(...years);
    setYear(minYear);
    setColor(colors[key]);
    setSelected(key);
  };

  const diseases = [
    { id: "malaria", name: "Malaria" },
    { id: "hiv", name: "HIV/AIDS" },
    { id: "ebola", name: "Ebola" },
    { id: "covid", name: "Covid-19" },
  ];

  return (
    <Visualisation
      title="The fight so far against these deadly diseases"
      // legend={<Legend title="Global cases each year" />}
      interaction={
        <YearSlider
          minYear={minYear}
          minYearOverall={minYear}
          maxYear={maxYear}
          maxYearOverall={maxYear}
          onChange={setYear}
          year={year}
          info={selectedData}
        />
      }
      cta="Use the slider below to compare cases and deaths in selected years"
      sources="Sources: Centers for Disease Control and Prevention, Joint United Nations Programme on HIV/AIDS, World Health Organization, HIV.gov, National Institute of Allergy and Infectious Diseases. Notes: Ebola cases and deaths for 2018 and 2019 are logged against 2018. Data as of 25 August 2021."
    >
      <div className={s.stage} style={{ "--color": color }}>
        <div className={s.buttons}>
          {diseases.map(({ id, name }) => (
            <TitleSquare
              color={colors[id]}
              onClick={() => setDisease(id)}
              isActive={selected === id}
            >
              {name}
            </TitleSquare>
          ))}
        </div>

        <div className={s.chart}>
          <div className={s.container}>
            <div className={cn(s.label, s.labelAbove)}>
              <p>New cases</p>
              <p className={s.figure}>{formatNumber(cases)}</p>
            </div>
            <div className={s.wrap}>
              <div
                className={cn(s.cases, { [s.isZero]: cases === 0 })}
                style={{
                  "--size": Math.max(Math.sqrt(cases) / Math.sqrt(max), 0),
                }}
              />
              <div
                className={cn(s.deaths, { [s.isZero]: deaths === 0 })}
                style={{
                  "--size": Math.max(Math.sqrt(deaths) / Math.sqrt(max), 0),
                }}
              />
            </div>
            <div className={cn(s.label, s.labelBelow)}>
              <p>Deaths</p>
              <p className={s.figure}>{formatNumber(deaths)}</p>
            </div>
          </div>
        </div>
      </div>
    </Visualisation>
  );
};

export default Caseloads;
