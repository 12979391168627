import React, { useState, useRef, useCallback } from "react";
import useMouse from "@react-hook/mouse-position";
import Tooltip from "components/Tooltip";

const useMouseLatest = (ref) => {
  const lastMouse = useRef({
    x: null,
    y: null,
    elementWidth: null,
    elementHeight: null,
  });

  const mouse = useMouse(ref, {
    enterDelay: 0,
    leaveDelay: 0,
    fps: 30,
  });

  if (mouse.x !== null && mouse.y !== null) {
    lastMouse.current = mouse;
  }

  if (mouse.x === null && mouse.y === null) {
    return lastMouse.current;
  }

  return mouse;
};

const useTooltip = (options: {}) => {
  const ref = useRef(null);
  const [current, setCurrent] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const mouse = useMouseLatest(ref);

  const onMouseOver = useCallback(
    (bar) => () => {
      setTooltipVisible(true);
      setCurrent(bar);
      // debouncedSetCurrent.callback(bar);
    },
    []
  );

  const onMouseOut = useCallback(
    () => () => {
      setTooltipVisible(false);
    },
    []
  );

  // alert(mouse.x, first);

  const TooltipWrapped = (
    <Tooltip
      x={mouse.x}
      y={mouse.y}
      onRight={mouse.x > mouse.elementWidth / 2}
      onBottom={mouse.y > mouse.elementHeight / 2}
      isVisible={tooltipVisible}
      {...current}
    />
  );

  return {
    ref,
    current,
    tooltipVisible,
    onMouseOver,
    onMouseOut,
    Tooltip: TooltipWrapped,
  };
};

export default useTooltip;
