import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Tooltip from "components/Tooltip";

const Slider = ({
  minYear,
  maxYear,
  minYearOverall = 1989,
  maxYearOverall = 2021,
  onChange,
  year,
  info = [],
  hideOutput,
}) => {
  const changeFunc = (e) => {
    onChange(+e.target.value);
  };

  let currentYear = year;

  const spread = maxYear - minYear + 1;

  const overallOffset = `calc(16px + (${
    (year - minYear) / (maxYear - minYear)
  } * (100% - 32px))`;

  const maxSpread = maxYearOverall - minYearOverall + 1;

  return (
    <div className={cn(s.wrap, { [s.hideOutput]: hideOutput })}>
      <div className={s.year}>{minYearOverall}</div>

      <div className={s.inputs}>
        <div
          className={s.inputWrap}
          style={{
            left: `${((minYear - minYearOverall) / maxSpread) * 100}%`,
            width: `${(spread / maxSpread) * 100}%`,
          }}
        >
          <input
            id="range"
            type="range"
            min={minYear}
            max={maxYear}
            step={1}
            onChange={changeFunc}
            value={year}
          />
          {!hideOutput && (
            <output
              className={s.yearLabel}
              for="range"
              value={year}
              style={{
                left: overallOffset,
              }}
            >
              {year}
            </output>
          )}

          <div className={s.tooltips}>
            {info
              .filter((d) => d.info)
              .map(({ year, info }) => (
                <Tooltip
                  year={year}
                  sub={info}
                  x={overallOffset}
                  y={54}
                  isVisible={year === currentYear}
                  center
                />
              ))}
          </div>

          {info.map(({ year, info }) => (
            <div
              className={cn(s.tick, { [s.noHover]: currentYear === year })}
              style={{
                left: `calc(18px + (${
                  (year - minYear) / (maxYear - minYear)
                } * (100% - 36px))`,
              }}
            >
              {info && (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11.5"
                      fill="white"
                      stroke="#292929"
                    />
                    <path
                      d="M12.798 4.708H10.17V7.408H12.798V4.708ZM12.798 9.28H10.17V19H12.798V9.28Z"
                      fill="#292929"
                    />
                  </svg>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={s.year}>{maxYearOverall}</div>
    </div>
  );
};

export default Slider;
