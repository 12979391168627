import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const TitleSquare = ({ children, color, onClick, isActive }) => {
  const Tag = onClick ? "button" : "div";

  return (
    <Tag
      className={cn(s.title, { [s.isButton]: onClick, [s.isActive]: isActive })}
      style={{ background: color }}
      onClick={onClick}
    >
      <span className={s.titleText}>{children}</span>
    </Tag>
  );
};

export default TitleSquare;
