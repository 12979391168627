import s from "./style.module.css";

const Legend = ({ items, title }) => {
  return (
    <div className={s.wrap}>
      {title && <p className={s.title}>{title}</p>}
      <div className={s.items}>
        {Object.entries(items).map(([name, color]) => (
          <div
            className={s.item}
            style={{
              "--color": color,
            }}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Legend;
