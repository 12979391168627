import React from "react";
import s from "./style.module.css";

const Legend = ({
  title,
  colors = ["#292929", "#545454", "#7F7F7F", "#A9A9A9", "#ffffff"],
}) => {
  return (
    <div>
      {title && <p className={s.title}>{title}</p>}
      <div className={s.wrap}>
        <span className={s.text}>More</span>
        {colors.map((color) => (
          <div className={s.color} style={{ background: color }}></div>
        ))}
        <span className={s.text}>Fewer</span>
      </div>
    </div>
  );
};

export default Legend;
