import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Tooltip = ({
  year,
  from,
  to,
  title,
  sub,
  x,
  y,
  onRight,
  onBottom,
  isVisible,
  center,
}) => {
  return (
    <div
      className={s.positionWrap}
      style={{
        top: y,
        left: x,
      }}
    >
      <div
        className={cn(s.wrap, {
          [s.onRight]: onRight,
          [s.onBottom]: onBottom,
          [s.isVisible]: isVisible,
          [s.center]: center,
        })}
      >
        {(year || (from && to)) && (
          <h2 className={s.year}>
            {year && year} {from && to && `${from}–${to}`}
          </h2>
        )}
        {title && (
          <p className={s.title}>
            <strong>{title}</strong>
          </p>
        )}
        {sub && <p className={s.sub}>{sub}</p>}
      </div>
    </div>
  );
};

export default Tooltip;
